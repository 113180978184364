import { InputMask } from "@react-input/mask";
import { Suspense, useCallback, useContext, useRef } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { AppContext } from "../providers/AppProvider";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const app = useContext(AppContext);
  const api = useApi();

  const navigate = useNavigate();

  const { formState, register, handleSubmit, setError } = useForm({
    defaultValues: {
      cpf: "",
      password: "",
      recaptcha_token: "123456",
    },
  });

  const handleSuccess = useCallback(
    (response: any) => {
      Swal.close();

      app.setToken(response.token);

      navigate("/meus-numeros");
    },
    [navigate, app],
  );

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data ?? []).forEach(([field, messages]) => {
        setError(field as any, { type: "manual", message: messages[0] });
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [setError],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: "O Google reCAPTCHA não foi carregado corretamente. Atualize a página e tente novamente.",
        });

        return;
      }

      Swal.fire({
        title: "Aguarde...",
        text: "Estamos fazendo o seu login.",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      data.recaptcha_token = await executeRecaptcha("login");

      api.login(data).then(handleSuccess).catch(handleError);
    },
    [api, executeRecaptcha, handleError, handleSuccess],
  );

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">LOGIN</h1>

          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                className="mb-5"
                autoComplete="off"
                autoCorrect="off"
                noValidate
              >
                <div className="mb-4">
                  <h2 className="fs-5 mb-3">DADOS DE ACESSO</h2>

                  <Row>
                    <Col xs={12} className="mb-3">
                      <Form.Label htmlFor="cpf">CPF*</Form.Label>
                      <Form.Control
                        id="cpf"
                        as={InputMask}
                        mask="___.___.___-__"
                        placeholder="___.___.___-__"
                        replacement={{ _: /\d/ }}
                        isInvalid={!!formState.errors.cpf}
                        {...register("cpf", {
                          // required: "Este campo é obrigatório",
                          // validate: validateCPF,
                        })}
                        autoFocus
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.cpf?.message}
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs={12} className="mb-3">
                      <Form.Label htmlFor="password">Senha*</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Informe sua senha"
                        {...register("password", {
                          // required: "Este campo é obrigatório",
                          // minLength: 8,
                        })}
                        isInvalid={!!formState.errors.password}
                      />
                      {formState.errors.password ? (
                        <Form.Control.Feedback type="invalid">
                          {formState.errors.password?.type === "minLength"
                            ? "A senha deve ter no mínimo 8 caracteres"
                            : formState.errors.password?.message}
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Text className="text-muted">
                          Mínimo de 8 caracteres
                        </Form.Text>
                      )}
                    </Col>
                  </Row>

                  {formState.errors.recaptcha_token && (
                    <Alert variant="danger" className="mb-3">
                      {formState.errors.recaptcha_token.message}
                    </Alert>
                  )}
                </div>

                <div className="d-flex gap-3 justify-content-center">
                  <Button type="submit" variant="primary" className="button">
                    LOGIN
                  </Button>

                  <Button
                    type="button"
                    variant="outline-primary"
                    className="button"
                    onClick={() => navigate("/esqueci-minha-senha")}
                  >
                    ESQUECI MINHA SENHA
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Suspense>
  );
}
